import { Card, CardContent, FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './App.css';
import InfoBox from './InfoBox';
import Maps from './Maps';
import LineGraph from './LineGraph';
import Table from "./Table";
import { sortData, prettyPrintStat } from "./util";
import numeral from "numeral";
import "leaflet/dist/leaflet.css";

function App() {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('worldwide');
  const [countryInfo, setCountryInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [mapCountries, setMapCountries] = useState([]);
  const [casesType, setCasesType] = useState("cases");
  const [mapCenter, setMapCenter] = useState({ lat: 34.80746, lng: -40.4796 });
  const [mapZoom, setMapZoom] = useState(3);
  // https://disease.sh/v3/covid-19/countries
  
  useEffect(() => {
    fetch('https://disease.sh/v3/covid-19/all')
    .then((res) => res.json())
    .then((data) => {
      setCountryInfo(data)
    });
  }, [])

  useEffect(() => {
    const getCountriesData = async () => {
      await fetch('https://disease.sh/v3/covid-19/countries')
      .then((res) => res.json())
      .then((data) => {
        const countries = data.map( (country) => (
          {
            name: country.country,
            value: country.countryInfo.iso2
          }
        ));

          let sortedData = sortData(data);
          setMapCountries(data);
          setCountries(countries);
          setTableData(sortedData);
      });
    }

    getCountriesData();
  }, []);

  const onCountryChange = async (event) => {
    const countryCode = event.target.value;

    setCountry(countryCode);

    const url = countryCode === 'worldwide' ? 'https://disease.sh/v3/covid-19/all' : `https://disease.sh/v3/covid-19/countries/${countryCode}` ;

    await fetch(url)
    .then((res) => res.json())
    .then((data) => {
        setCountryInfo(data);
        setCountry(countryCode);
        setMapCenter([data.countryInfo.lat, data.countryInfo.long]);
        setMapZoom(4);
    });

  }  

  return (
    <div className="app">
      <div className="app__left">
      <h1>Covid-19 Tracker</h1>
        <FormControl className="app__dropdpown">
          <Select variant="outlined" value={country} onChange={onCountryChange} >
            <MenuItem value="worldwide" key={-1}>Worldwide</MenuItem>

            {
              countries.map( (country, index) => (
                <MenuItem value={country.value} key={index}>{country.name}</MenuItem>
              ))

            }

          </Select>
        </FormControl>

        <br />
        <br />

        <div className="app__stats">
          
          <InfoBox onClick={(e) => setCasesType("cases")} isRed active={casesType === "cases"} title="Coronavirus cases" cases={prettyPrintStat(countryInfo.todayCases)} total={numeral(countryInfo.cases).format('0.0a')}/>
          <InfoBox onClick={(e) => setCasesType("recovered")} active={casesType === "recovered"} title="Recovered" cases={prettyPrintStat(countryInfo.todayRecovered)} total={numeral(countryInfo.recovered).format("0.0a")}/>
          <InfoBox onClick={(e) => setCasesType("deaths")} isRed active={casesType === "deaths"} title="Deaths" cases={prettyPrintStat(countryInfo.todayDeath)} total={numeral(countryInfo.deaths).format("0.0a")}/>

        </div>
        
        <Maps
          countries={mapCountries}
          casesType={casesType}
          center={mapCenter}
          zoom={mapZoom}
          />
      </div>

      <Card className="app__right">
        <CardContent>
          <div className="app__information">
            <h3>Live cases by Country</h3>
            <Table countries={tableData} />
            <h3>Worldwide new cases</h3>
            <LineGraph casesType={casesType} />
          </div>
        </CardContent>
      </Card>
    
    </div>
  );
}

export default App;
