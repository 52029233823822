import React from 'react'
import './css/table.css'
import numeral from "numeral"

const Table = ({countries}) => {
    return (
        <div className="table">
            {countries.map( ({country, cases}, index) => (
                <div className="table__view" key={index}>
                    <div className="table__countryName">{country}</div>
                    <div className="table__countryCount"><strong>{numeral(cases).format("0,0")}</strong></div>
                </div>
            ))}
        </div>
    )
}

export default Table
