import { Card, CardContent } from '@material-ui/core'
import React from 'react'
import './css/infoBox.css'

const InfoBox = ({ title, cases, total, active, isRed, ...props }) => {
    return (
        <Card onClick={props.onClick} className={`infoBox ${active && "infoBox--selected"} ${isRed && "infoBox--red" }`}>
            <CardContent>
                <div className="infoBox__title">
                    {title}
                </div>
            
                <h2 className={`infoBox__cases ${!isRed && "infoBox__cases--green"}`}>{cases}</h2>

            
            
                <div className="infoBox__total" color="TextSecondary">
                    {total} Total
                </div>
            
            </CardContent>
        </Card>
    )
}

export default InfoBox
